

























import Vue from 'vue'
import { IJobReferral } from '@/types'
import { JobActionsContainer } from '../JobActionsContainer'
import { BaseBtnRefer } from '@/components'
import { BtnApply } from '../BtnApply'
import { BtnReject } from './components'
import { REPOSITORY_JOB_REFFERALS } from '@/repositiories'

export default Vue.extend({
    name: 'JobActionsReferred',

    components: {
        JobActionsContainer,
        BtnApply,
        BtnReject,
        BaseBtnRefer,
    },

    props: {
        job: {
            type: Object,
            required: true,
        },
        referralHash: {
            type: String,
            required: true,
        },
    },

    computed: {
        showReferDialogInitially(): boolean {
            return this.$route.query.referInitially === 'true'
        },
    },

    data: () => ({
        referral: undefined as undefined | IJobReferral,
        hasAlreadyApplied: false,
    }),

    async created() {
        try {
            this.referral = await REPOSITORY_JOB_REFFERALS.fetchReferral(this.referralHash)
            this.hasAlreadyApplied = !!this.referral.referredEmailJobApplicationId
        } catch (error) {
            alert(error)
        }
    },
})
