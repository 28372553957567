








import Vue from 'vue'

export default Vue.extend({
    name: 'FieldRejectionDescription',

    model: {
        prop: 'rejectionDescription',
        event: 'update:rejection-description',
    },

    props: {
        rejectionDescription: {
            type: String,
            required: true,
        },
    },

    computed: {
        rejectionDescriptionInternal: {
            get(): string {
                return this.rejectionDescription
            },
            set(description: string) {
                this.$emit('update:rejection-description', description)
            },
        },
    },
})
