






import { REPOSITORY_JOB_REFFERALS } from '@/repositiories'
import { IJobReferralRejectionPayload } from '@/types'
import Vue from 'vue'

export default Vue.extend({
    name: 'BtnReject',

    props: {
        rejection: {
            type: Object as () => IJobReferralRejectionPayload,
            required: true,
        },
    },

    data: () => ({
        isRequestInProgress: false,
    }),

    computed: {
        isValid(): boolean {
            return !!this.rejection.rejectionReason
        },
    },

    methods: {
        async submit() {
            this.isRequestInProgress = true

            try {
                await REPOSITORY_JOB_REFFERALS.rejectRefferal(this.rejection)

                this.$emit('rejected')
            } catch (error) {
                alert(error.message)
            }

            this.isRequestInProgress = false
        },
    },
})
