



















import Vue from 'vue'
import { BaseBtnRefer } from '@/components'
import { JobActionsContainer } from '../JobActionsContainer'
import { BtnApply } from '../BtnApply'

export default Vue.extend({
    name: 'JobActionsDefault',

    components: {
        JobActionsContainer,
        BaseBtnRefer,
        BtnApply,
    },

    props: {
        job: {
            type: Object,
            required: true,
        },
        referrers: {
            type: Array,
            required: true,
        },
    },

    computed: {
        signUpInvitationUuid(): string | undefined {
            return this.$route.query.invitationUuid as string
        },
    },
})
