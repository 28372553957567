
























import Vue from 'vue'
import { IJobApplicationPayload, IJobReferral, IStateAuth } from '@/types'
import {
    BaseFieldText,
    BaseFieldTextarea,
    BaseFieldFile,
    BaseFieldNoticePeriod,
} from '@/components'
import { SelectSkills, SelectTimeAvailability } from './components'
import { createNamespacedHelpers } from 'vuex'
import { STORE_MODULE } from '@/enums'

const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default Vue.extend({
    name: 'FormApplication',

    components: {
        BaseFieldText,
        BaseFieldTextarea,
        BaseFieldFile,
        BaseFieldNoticePeriod,
        SelectSkills,
        SelectTimeAvailability,
    },

    model: {
        prop: 'application',
        event: 'update:application',
    },

    props: {
        application: Object as () => Partial<IJobApplicationPayload>,
        job: {
            type: Object,
            required: true,
        },
        referral: Object as () => IJobReferral,
    },

    data() {
        return {
            applicationInternal: {
                jobId: this.job.id,
                referralId: this.referral?.id,
                firstName: '',
                lastName: '',
                email: '',
                noticePeriod: undefined,
                areAllSkillsMatched: false,
                isPartTimeAvailable: false,
                descriptionWhyImGood: '',
                descriptionWhatIsMissing: '',
                filesToAttach: [],
            } as Partial<IJobApplicationPayload>,
        }
    },

    computed: {
        ...(mapState(['userAccount']) as MapStateToComputed<IStateAuth>),
    },

    watch: {
        applicationInternal: {
            immediate: true,
            deep: true,
            handler(application: Partial<IJobApplicationPayload>) {
                this.$emit('update:application', application)
            },
        },
    },

    created() {
        this.applicationInternal.firstName =
            this.referral?.referralName || this.userAccount?.firstName || ''
        this.applicationInternal.email = this.referral?.referralEmail || ''
    },
})
