








import { REPOSITORY_JOB_REFFERALS } from '@/repositiories'
import { IJobReferralRejectionReason } from '@/types'
import Vue from 'vue'

interface ISelectOption {
    text: string
    value: IJobReferralRejectionReason
}

export default Vue.extend({
    name: 'SelectRejectionReason',

    model: {
        prop: 'rejectionReason',
        event: 'update:rejection-reason',
    },

    props: {
        rejectionReason: Object as () => IJobReferralRejectionReason,
    },

    computed: {
        rejectionReasonInternal: {
            get(): undefined | IJobReferralRejectionReason {
                return this.rejectionReason
            },
            set(reason: IJobReferralRejectionReason) {
                this.$emit('update:rejection-reason', reason)
            },
        },

        options(): ISelectOption[] {
            return (this.rejectionReasonList ?? []).map((reason) => ({
                text: reason.name,
                value: reason,
            }))
        },
    },

    data: () => ({
        rejectionReasonList: undefined as undefined | IJobReferralRejectionReason[],
    }),

    async created() {
        try {
            const rejectionReasonId = Number(this.$route.query.reason)
            this.rejectionReasonList = await REPOSITORY_JOB_REFFERALS.fetchReferralRejectionReasons()
            const selectedReason = this.rejectionReasonList.find(
                (reason) => reason.id === rejectionReasonId
            )
            this.rejectionReasonInternal = selectedReason || this.rejectionReasonList[0]
        } catch (error) {
            alert(error.errorMessage)
        }
    },
})
