<template>
    <BaseSection v-if="job" :key="jobId">
        <ReferralInfo v-if="referralHash" v-bind="{ referralHash }" />

        <SearchResultNavigation />

        <BaseJobDetailsHeader v-bind="{ job }" />

        <BaseJobDetailsData v-bind="{ job }" class="margin-bottom" />

        <template v-if="!isJobPage">
            <JobActionsReferred v-if="referralHash" v-bind="{ job, referralHash }" />
            <JobActionsDefault v-else v-bind="{ job, referrers }" />
        </template>
    </BaseSection>
</template>

<script>
import { BaseSection, BaseJobDetailsHeader, BaseJobDetailsData } from '@/components'
import {
    ReferralInfo,
    SearchResultNavigation,
    JobActionsDefault,
    JobActionsReferred,
} from './components'

export default {
    name: 'JobDetails',

    components: {
        BaseSection,
        BaseJobDetailsHeader,
        BaseJobDetailsData,
        ReferralInfo,
        SearchResultNavigation,
        JobActionsDefault,
        JobActionsReferred,
    },

    props: {
        jobId: {
            type: [String, Number],
            required: false,
        },
    },

    data() {
        return {
            job: undefined,
            isJobPage: false,
            referrers: [],
        }
    },

    computed: {
        referralHash() {
            return this.$route.query.referral
        },
    },

    watch: {
        jobId: {
            immediate: true,
            handler() {
                this.job = undefined
                this.setData()
            },
        },
    },

    methods: {
        setData() {
            window.scrollTo(0, 0)
            this.isJobPage =
                this.$route.fullPath.includes('job-edit') ||
                this.$route.fullPath.includes('job-add')

            this.fetchJob()

            if (this.$route.query.referral) this.saveReferralHash()

            if (
                localStorage.referring &&
                JSON.parse(localStorage.referring)[this.$route.params.id]
            ) {
                this.getAllReferrers()
            }

            if (this.$route.query.shr) this.shareTracking()
        },

        fetchJob() {
            let id = this.jobId || this.$route.query.edit

            this.$store.state.backend
                .get(`/getjobs/${id}`)
                .then((ret) => {
                    this.job = ret.data
                })
                .catch((error) => alert('Error (mounted):', error))
        },

        saveReferralHash() {
            const hash = this.$route.query.referral
            const jobId = this.$route.params.id

            if (localStorage.referring) {
                let users = JSON.parse(localStorage.getItem('referring'))
                let jobHashes = users[jobId] || []
                users[jobId] = [...new Set([...jobHashes, hash])]

                localStorage.setItem('referring', JSON.stringify(users))
            } else {
                localStorage.setItem('referring', JSON.stringify({ [jobId]: [hash] }))
            }
        },

        shareTracking() {
            this.$store.state.backend
                .post('/share/track', {
                    sharing_user: this.$route.query.shr,
                    medium: this.$route.query.utm_medium,
                })
                .catch((err) => alert(err))
        },

        getAllReferrers() {
            const referrersOfThisJob = JSON.parse(localStorage.referring)[this.$route.params.id]

            this.$store.state.backend
                .post('/auth/referring-users', { hashes: referrersOfThisJob })
                .then((res) => (this.referrers = res.data))
        },
    },
}
</script>

<style lang="sass" scoped>
.margin-bottom
    +margin(bottom)
</style>
