

















import Vue from 'vue'
import { ISkillItem, ISkill, ISkillGroup } from './models'
import { SkillItem } from './components'

export default Vue.extend({
    name: 'SelectSkills',

    components: { SkillItem },

    model: {
        prop: 'areAllSkillsMatched',
        event: 'update:are-all-skills-matched',
    },

    props: {
        areAllSkillsMatched: {
            type: Boolean,
            required: true,
        },
        job: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        skillItems: [] as ISkillItem[],
    }),

    computed: {
        selectedSkillsCount(): number {
            return this.skillItems.reduce((result, item) => {
                if (item.isSelected) ++result

                return result
            }, 0)
        },
    },

    watch: {
        selectedSkillsCount() {
            const areAllSkillsMatched = this.selectedSkillsCount === this.skillItems.length

            this.$emit('update:are-all-skills-matched', areAllSkillsMatched)
        },
    },

    created() {
        this.skillItems = [...this.getSkillItems(), ...this.getSkillItemsFromGroups()]
    },

    methods: {
        getSkillItems(): ISkillItem[] {
            return this.job.skills.map((skill: ISkill, i: number) => ({
                id: `${i}-${skill.name}`,
                isSelected: false,
                name: skill.name,
            }))
        },

        getSkillItemsFromGroups(): ISkillItem[] {
            return this.job.groupedSkills.map((group: ISkillGroup, i: number) => {
                const skillNames = group.skills.map(({ name }) => name).join(', ')
                const description = `At least ${group.groupSkillsCount} of: ${skillNames}`

                return {
                    id: `${i}-${group.groupName}`,
                    isSelected: false,
                    name: group.groupName,
                    description,
                }
            })
        },

        toggleSkillItemSelection(skillItem: ISkillItem) {
            const itemIndex = this.skillItems.findIndex((item) => item === skillItem)

            this.$set(this.skillItems, itemIndex, {
                ...skillItem,
                isSelected: !skillItem.isSelected,
            })
        },
    },
})
