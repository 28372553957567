




















import Vue from 'vue'
import {
    IJobReferral,
    IJobReferralRejectionPayload,
    IJobReferralRejectionReason,
} from '@/types'
import { BaseModal } from '@/components'
import { SelectRejectionReason, FieldRejectionDescription, BtnReject } from './components'
import { ROUTE } from '@/enums'

export default Vue.extend({
    name: 'DialogReject',

    components: { BaseModal, SelectRejectionReason, FieldRejectionDescription, BtnReject },

    model: {
        prop: 'isShown',
        event: 'update:is-shown',
    },

    props: {
        isShown: {
            type: Boolean,
            required: true,
        },
        job: {
            type: Object,
            required: true,
        },
        referral: {
            type: Object as () => IJobReferral,
            required: true,
        },
    },

    data: () => ({
        ROUTE,
        rejectionReason: undefined as undefined | IJobReferralRejectionReason,
        rejectionDescription: '',
        hasBeenRejected: false,
    }),

    computed: {
        isShownInternal: {
            get(): boolean {
                return this.isShown
            },
            set(isShown: boolean) {
                this.$emit('update:is-shown', isShown)
            },
        },

        rejection(): IJobReferralRejectionPayload {
            return {
                referralHash: this.referral.referralHash,
                rejectionReason: this.rejectionReason!,
                rejectionDescription: this.rejectionDescription,
            }
        },
    },

    watch: {
        isShownInternal(isShown: boolean) {
            if (isShown) return

            Object.assign(this.$data, (this.$options.data as Function).apply(this))
        },
    },

    methods: {
        handleRejected() {
            this.hasBeenRejected = true
            this.$emit('rejected')
        },
    },
})
