


























import Vue from 'vue'

export default Vue.extend({
    name: 'SelectTimeAvailability',

    model: {
        prop: 'isPartTimeAvailable',
        event: 'update:is-part-time-available',
    },

    props: {
        isPartTimeAvailable: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        isPartTimeAvailableInternal: {
            get(): boolean {
                return this.isPartTimeAvailable
            },
            set(isPartTimeAvailable: boolean) {
                this.$emit('update:is-part-time-available', isPartTimeAvailable)
            },
        },
    },
})
