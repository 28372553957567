



















import Vue from 'vue'
import { ISkillItem } from '../../models'

export default Vue.extend({
    name: 'SkillItem',

    props: {
        skillItem: {
            type: Object as () => ISkillItem,
            required: true,
        },
    },

    methods: {
        toggleSelection() {
            this.$emit('toggle', this.skillItem)
        },
    },
})
