



















import Vue from 'vue'
import { IJobReferral } from '@/types'
import { faPaperPlane, faCheck } from '@fortawesome/free-solid-svg-icons'
import { DialogApply } from './components'

export default Vue.extend({
    name: 'BtnApply',

    components: { DialogApply },

    props: {
        job: {
            type: Object,
            required: true,
        },
        referral: Object as () => IJobReferral,
    },

    data: () => ({
        isDialogShown: false,
        hasAlreadyApplied: false,
        faPaperPlane,
        faCheck,
    }),

    created() {
        if (!!this.referral?.referredEmailJobApplicationId) this.hasAlreadyApplied = true
    },

    methods: {
        handleApplied() {
            this.hasAlreadyApplied = true
            this.$emit('applied')
        },
    },
})
