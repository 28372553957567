
















import Vue from 'vue'

export default Vue.extend({
    name: 'JobActionsContainer',

    props: {
        job: {
            type: Object,
            required: true,
        },
    },
})
