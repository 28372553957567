













import Vue from 'vue'
import { IJobReferral } from '@/types'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { DialogReject } from './components'

export default Vue.extend({
    name: 'BtnReject',

    components: { DialogReject },

    props: {
        job: {
            type: Object,
            required: true,
        },
        referral: {
            type: Object as () => IJobReferral,
            required: true,
        },
    },

    data: () => ({
        isDialogShown: false,
        hasRejected: false,
        faTimesCircle,
    }),

    created() {
        const queryReasonId: number = Number(this.$route.query.reason)
        if (queryReasonId) this.isDialogShown = true
        if (!!this.referral.referralRejectionId) this.hasRejected = true
    },
})
