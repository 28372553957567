






import Vue from 'vue'
import { ROUTE } from '@/enums'

export default Vue.extend({
    name: 'SearchResultNavigation',

    data: () => ({ ROUTE }),
})
