
























import Vue from 'vue'
import { BaseModal } from '@/components'
import { IJobApplicationPayload, IJobReferral } from '@/types'
import { FormApplication, BtnApply } from './components'

export default Vue.extend({
    name: 'DialogApply',

    components: { BaseModal, FormApplication, BtnApply },

    model: {
        prop: 'isShown',
        event: 'update:is-shown',
    },

    props: {
        isShown: {
            type: Boolean,
            required: true,
        },
        job: {
            type: Object,
            required: true,
        },
        referral: Object as () => IJobReferral,
    },

    data: () => ({
        application: undefined as undefined | IJobApplicationPayload,
        hasApplied: false,
    }),

    computed: {
        isShownInternal: {
            get(): boolean {
                return this.isShown
            },
            set(isShown: boolean) {
                this.$emit('update:is-shown', isShown)
            },
        },

        isValid(): boolean {
            if (!this.application) return false

            const { firstName, lastName, email, noticePeriod } = this.application

            return !!firstName.length && !!lastName.length && !!email.length && !!noticePeriod
        },
    },

    watch: {
        isShownInternal(isShown: boolean) {
            if (isShown) return

            Object.assign(this.$data, (this.$options.data as Function).apply(this))
        },
    },

    methods: {
        handleApplication() {
            this.hasApplied = true
            this.$emit('applied')
        },
    },
})
