

























import Vue from 'vue'
import { BaseAvatar } from '@/components'
import { REPOSITORY_JOB_REFFERALS } from '@/repositiories'
import { IJobReferral } from '@/types'

export default Vue.extend({
    name: 'ReferralInfo',

    components: { BaseAvatar },

    props: {
        referralHash: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        referral: undefined as undefined | IJobReferral,
    }),

    async created() {
        try {
            this.referral = await REPOSITORY_JOB_REFFERALS.fetchReferral(this.referralHash)
        } catch (error) {
            alert(error)
        }
    },
})
