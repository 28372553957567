






import Vue from 'vue'
import { REPOSITORY_JOB_APPLICATIONS } from '@/repositiories'
import { IJobApplicationPayload } from '@/types'

export default Vue.extend({
    name: 'BtnApply',

    props: {
        application: Object as () => IJobApplicationPayload,
        isValid: {
            type: Boolean,
            required: true,
        },
    },

    data: () => ({
        isRequestInProgress: false,
    }),

    methods: {
        async submit() {
            this.isRequestInProgress = true

            try {
                await REPOSITORY_JOB_APPLICATIONS.createJobApplication(this.application)

                this.$emit('applied')
            } catch (error) {
                alert(error.errorMessage)
            }

            this.isRequestInProgress = false
        },
    },
})
